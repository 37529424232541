<template>
  <v-container fluid>
    <!-- En-tête-->
    <h2 v-if="header.clientContrat">
      {{ $t('dashboard.For_period') }} {{ header.factureEleve.periode }}
    </h2>

    <v-row
      v-if="header.clientContrat"
      justify="center"
      no-gutters
    >
      <!-- Clients et Contrats-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <!-- Clients-->
            <div>
              <h1 class="blue--text">
                {{ header.clientContrat.clientsActif }}
              </h1>
            </div>
            <div class="mt-1">
              <span>Clients</span>
            </div>
            <span class="caption grey--text font-weight-light">
              {{ $t('Active') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Contrat-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <!-- Contrats-->
            <div>
              <h1 class="blue--text">
                {{ header.clientContrat.contratsActif }}
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('Contract') }}s</span>
            </div>
            <span class="caption grey--text font-weight-light">
              {{ $t('Active') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Factures-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div>
              <h1 class="blue--text">
                {{ header.numbreFacturePeriode.facturesPeriode }}
              </h1>
            </div>
            <div class="mt-1">
              <span class="blue--text">
                {{ header.numbreFacturePeriode.total.toFixed(2) }} $
              </span>
            </div>
            <div class="mt-1">
              <span>{{ $t('contract.Bill') }}s </span>
            </div>
            <div class="caption grey--text font-weight-light mt-1">
              <span>{{ header.numbreFacturePeriode.periode }} </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Factures impayés-->
      <v-col
        class="pointer"
        cols="12"
        lg="2"
        @click="openDialogFactures('facturesImpayees', $t('Chart.List_bad_payers'))"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div>
              <h1 class="blue--text">
                {{ header.factureImpaye.resultImpayees }}
                <v-icon
                  class="mb-1"
                  light
                  small
                  color="blue"
                >
                  mdi-eye-outline
                </v-icon>
              </h1>
            </div>
            <div class="mt-1">
              <span class="blue--text">
                {{ header.factureImpaye.total.toFixed(2) }} $
              </span>
            </div>
            <div class="mt-1">
              <span class="blue--text">
                {{ header.factureImpaye.qt_clients }} {{ $t('dashboard.qt_clients') }}
              </span>
            </div>
            <div class="mt-1">
              <span>{{ $t('dashboard.Unpaid_invoices') }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Factures Élevé-->
      <v-col
        class="pointer"
        cols="12"
        lg="2"
        @click="openDialogFactures('factureEleve', `${$t('Chart.List_high_bills')} <br> ${header.factureEleve.periode}`)"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div>
              <h1 class="blue--text">
                {{ header.factureEleve.facturesElevees }}
                <v-icon
                  class="mb-1"
                  light
                  small
                  color="blue"
                >
                  mdi-eye-outline
                </v-icon>
              </h1>
            </div>
            <div class="mt-1">
              <span class="blue--text">{{ header.factureEleve.total.toFixed(2) }} $</span>
            </div>
            <div class="mt-1">
              <span>{{ $t('contract.Bill') }}s > {{ header.factureEleve.parametre }} $</span>
            </div>
            <div class="mt-1">
              <span class="caption grey--text font-weight-light">{{ header.factureEleve.periode }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Commandes-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div>
              <h1 class="blue--text">
                {{ header.commandeEnCours.commandesEnCours }}
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('sidebar.order') }}</span>
            </div>
            <div>
              <span class="caption grey--text font-weight-light">
                {{ $t('In_progress') }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Profit et frais de retard-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div>
              <h1 class="blue--text">
                {{ header.profit.profit_brut }} $
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('contract.bill.Administrative_costs') }}</span>
            </div>
            <div class="caption grey--text font-weight-light mt-1">
              <span>{{ header.profit.periode }} </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Frais ajustement-->
      <v-col
        class="pointer"
        cols="12"
        lg="2"
        @click="openDialogFraisAjustement($t('contract.Adjusted_invoice'))"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div v-if="header.fraisAjustement.count">
              <h1 class="blue--text">
                {{ header.fraisAjustement.count }}
                <v-icon
                  class="mb-1"
                  light
                  small
                  color="blue"
                >
                  mdi-eye-outline
                </v-icon>
              </h1>
              <div class="mt-1">
                <span>{{ $t('contract.Adjusted_invoice') }}</span>
              </div>
            </div>
            <div
              v-if="header.fraisAjustement.total"
              class="mt-1"
            >
              <span class="blue--text">
                {{ header.fraisAjustement.total.toFixed(2) }} $
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Payments en retard-->
      <v-col
        class="pointer"
        cols="12"
        lg="2"
        @click="openDialogFactures('paiementRetard', `${$t('Chart.List_invoices_late_payment')} <br> ${$t('dashboard.90_days')}`)"
      >
        <v-card :height="cardHeight">
          <v-card-text v-if="$company === 'uqam'">
            <div>
              <h1 class="blue--text">
                {{ header.paiementRetard.count }}
                <v-icon
                  class="mb-1"
                  light
                  small
                  color="blue"
                >
                  mdi-eye-outline
                </v-icon>
              </h1>
            </div>
            <div class="mt-1">
              <span class="blue--text">
                {{ header.paiementRetard.total.toFixed(2) }} $
              </span>
            </div>
            <div class="mt-1">
              <span>{{ $t('dashboard.Late_payment') }}</span>
            </div>
            <span class="caption grey--text font-weight-light">
              {{ $t('dashboard.90_days') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Mauvais payeur-->
      <v-col
        class="pointer"
        cols="12"
        lg="2"
        @click="openDialogMauvaisPayeur($t('dashboard.Bad_payer'))"
      >
        <v-card :height="cardHeight">
          <v-card-text v-if="$company === 'uqam'">
            <div>
              <h1 class="blue--text">
                {{ header.mauvaisPayeur }}
                <v-icon
                  class="mb-1"
                  light
                  small
                  color="blue"
                >
                  mdi-eye-outline
                </v-icon>
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('dashboard.Bad_payer') }} </span>
            </div>
            <div class="mt-1">
              <span class="caption grey--text font-weight-light">2+ {{ $t('dashboard.Late_payment') }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Suspendus-->
      <v-col
        class="pointer"
        cols="12"
        lg="2"
        @click="openDialogContrats('suspendus', $t('dashboard.Suspended_contracts'))"
      >
        <v-card :height="cardHeight">
          <v-card-text v-if="$company === 'uqam'">
            <div>
              <h1 class="blue--text">
                {{ header.contratSuspendu }}
                <v-icon
                  class="mb-1"
                  light
                  small
                  color="blue"
                >
                  mdi-eye-outline
                </v-icon>
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('dashboard.Suspended_contracts') }} </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Ex employés-->
      <v-col
        class="pointer"
        cols="12"
        lg="2"
        @click="openDialogExEmployeC2Special(`${$t('dashboard.Ex_employee')} <br> ${$t('dashboard.C2_Out_of_limit')} <br> ${$t('dashboard.Special_employe')}`)"
      >
        <v-card :height="cardHeight">
          <v-card-text v-if="$company === 'uqam'">
            <!-- Ex employé-->
            <div>
              <h1 class="blue--text">
                {{ header.exEmploye }}
                <v-icon
                  class="mb-1"
                  light
                  small
                  color="blue"
                >
                  mdi-eye-outline
                </v-icon>
              </h1>
              <div class="mt-1">
                <span>{{ $t('dashboard.Ex_employee') }}</span>
              </div>
            </div>

            <!-- C2 hors limite-->
            <div class="mt-2">
              <h1 class="blue--text">
                {{ header.c2HorsLimite }}
              </h1>
              <div class="mt-1">
                <span>{{ $t('dashboard.C2_Out_of_limit') }}</span>
              </div>
            </div>

            <!-- Statut special-->
            <div class="mt-2">
              <h1 class="blue--text">
                {{ header.specialEmploye }}
              </h1>
              <div class="mt-1">
                <span>{{ $t('dashboard.Special_employe') }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Graphique beigne-->
    <v-row>
      <v-col
        cols="12"
      >
        <base-material-card
          id="pie"
          color="info"
          icon="mdi-chart-pie"
          class="px-4 py-3"
        >
          <v-row>
            <!-- Employé-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart1"
                :data="chart1"
                :height="300"
                :width="300"
              />
            </v-col>

            <!-- Client-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart2"
                :data="chart2"
                :height="300"
                :width="300"
              />
            </v-col>

            <!-- C1, C2-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart3"
                :data="chart3"
                :height="300"
                :width="300"
              />
            </v-col>
          </v-row>

          <v-row>
            <!-- Employé-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart4"
                :data="chart4"
                :height="300"
                :width="300"
              />
            </v-col>

            <!-- Client-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart5"
                :data="chart5"
                :height="300"
                :width="300"
              />
            </v-col>

            <!-- C1, C2-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart6"
                :data="chart6"
                :height="300"
                :width="300"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- Bar Chart-->
    <v-row>
      <v-col
        cols="12"
      >
        <base-material-card
          id="coloured-line"
          color="info"
          icon="mdi-chart-bar"
          class="px-4 py-3"
        >
          <v-row>
            <v-col
              cols="12"
              lg="4"
            >
              <div class="text-center">
                <h4 v-if="graphBar1">
                  {{ graphBar1.header }}
                </h4>
              </div>
              <bar-chart
                v-if="graphBar1"
                :data="graphBar1"
              />
            </v-col>

            <v-col
              cols="12"
              lg="4"
            >
              <div class="text-center">
                <h4 v-if="graphBar2">
                  {{ graphBar2.header }}
                </h4>
              </div>
              <bar-chart
                v-if="graphBar2"
                :data="graphBar2"
              />
            </v-col>

            <v-col
              cols="12"
              lg="4"
            >
              <div class="text-center">
                <h4 v-if="graphBar3">
                  {{ graphBar3.header }}
                </h4>
              </div>

              <bar-chart
                v-if="graphBar3"
                :data="graphBar3"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- Line Chart-->
    <v-row>
      <v-col
        cols="12"
      >
        <base-material-card
          id="coloured-line"
          color="info"
          icon="mdi-chart-timeline-variant"
          class="px-4 py-3"
        >
          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <div class="text-center">
                <h4 v-if="graphLine1">
                  {{ graphLine1.header }}
                </h4>
              </div>
              <line-chart
                v-if="graphLine1"
                :data="graphLine1"
              />
            </v-col>

            <v-col
              cols="12"
              lg="6"
            >
              <div class="text-center">
                <h4 v-if="graphLine2">
                  {{ graphLine2.header }}
                </h4>
              </div>
              <line-chart
                v-if="graphLine2"
                :data="graphLine2"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <!--  Dialog-->
    <v-dialog
      v-model="dialog.state"
      max-width="11000px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h3"
            v-html="dialog.title "
          />
          <v-icon
            aria-label="Close"
            @click="dialog.state = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <!-- Table facture-->
          <rapport-table-component
            v-if="dialog.type === 'facture'"
            :table="tableClientDetails"
            :table-headers="tableHeadersDetails"
          />

          <!--  Contrat-->
          <contract
            v-if="dialog.type === 'contrat'"
            :client-contrats="tableClientDetails"
          />

          <!-- Mauvais payeur-->
          <template v-if="dialog.type === 'mauvaisPayeur'">
            <!-- Search-->
            <v-text-field
              v-model="searchMauvaisPayeur"
              class="mb-5"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
            />

            <v-data-table
              :headers="tableHeaderMauvaisPayeur"
              :items="tableMauvaisPayeur"
              item-key="id"
              :search="searchMauvaisPayeur"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :items-per-page="50"
            />
          </template>

          <!-- Ex employé et C2 hors limite-->
          <template v-if="dialog.type === 'exEmployeC2'">
            <!-- Table Ex employé-->
            <span class="text-h3">{{ $t('dashboard.Ex_employee') }}</span>
            <!-- Search-->
            <v-text-field
              v-model="searchExEmploye"
              class="mb-5"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
            />

            <v-data-table
              :headers="tableHeaderExEmploye"
              :items="tableExEmploye"
              item-key="id"
              :search="searchExEmploye"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :items-per-page="50"
            >
              <!-- Numéro de téléphone-->
              <template
                slot="item.no"
                slot-scope="props"
              >
                <span>
                  {{ props.item.no | phoneNumberFormat }}
                </span>
              </template>

              <!-- Total-->
              <template
                slot="item.total"
                slot-scope="props"
              >
                <span v-if="props.item.total !== null">
                  {{ props.item.total.toFixed(2) }}$
                </span>
              </template>
            </v-data-table>

            <!-- Table C2 hors limite-->
            <span class="text-h3">{{ $t('dashboard.C2_Out_of_limit') }}</span>
            <!-- Search-->
            <v-text-field
              v-model="searchC2HorsLimite"
              class="mb-5"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
            />

            <v-data-table
              :headers="tableHeaderC2HorsLimite"
              :items="tablec2HorsLimite"
              item-key="id"
              :search="searchC2HorsLimite"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :items-per-page="50"
            />

            <!-- Special employé-->
            <span class="text-h3">{{ $t('dashboard.Special_employe') }}</span>
            <!-- Search-->
            <v-text-field
              v-model="searchSpecialEmploye"
              class="mb-5"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
            />

            <v-data-table
              :headers="tableHeaderSpecialEmploye"
              :items="tableSpecialEmploye"
              item-key="id"
              :search="searchSpecialEmploye"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :items-per-page="50"
            >
              <!-- Numéro de téléphone-->
              <template
                slot="item.no"
                slot-scope="props"
              >
                <span>
                  {{ props.item.no | phoneNumberFormat }}
                </span>
              </template>

              <!-- Total-->
              <template
                slot="item.total"
                slot-scope="props"
              >
                <span v-if="props.item.total !== null">
                  {{ props.item.total.toFixed(2) }}$
                </span>
              </template>
            </v-data-table>
          </template>

          <!-- Credit Actif-->
          <template v-if="dialog.type === 'fraisAjustement'">
            <!-- Search-->
            <v-text-field
              v-model="searchFraisAjustement"
              class="mb-5"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
            />

            <v-data-table
              :headers="tableHeaderFraisAjustement"
              :items="tableFraisAjustement"
              item-key="id"
              :search="searchFraisAjustement"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :items-per-page="50"
            >
              <!-- Sous-total-->
              <template
                slot="item.montant_at"
                slot-scope="props"
              >
                <span v-if="props.item.montant_at !== null">
                  {{ props.item.montant_at.toFixed(2) }}$
                </span>
              </template>

              <!-- Total-->
              <template
                slot="item.montant_total"
                slot-scope="props"
              >
                <span v-if="props.item.montant_total !== null">
                  {{ props.item.montant_total.toFixed(2) }}$
                </span>
              </template>

              <!-- Frais ajustement-->
              <template
                slot="item.frais_ajustement"
                slot-scope="props"
              >
                <span v-if="props.item.frais_ajustement !== null">
                  {{ props.item.frais_ajustement.toFixed(2) }}$
                </span>
              </template>

              <!-- Frais ajustement-->
              <template
                slot="item.frais_ajustement"
                slot-scope="props"
              >
                <span v-if="props.item.frais_ajustement !== null">
                  {{ props.item.frais_ajustement.toFixed(2) }}$
                </span>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // Components
  import Contract from '@/components/Client/Contract'
  // Exemple https://codesandbox.io/s/93m1lpjrvr?file=/src/App.vue:32-44
  import AreaChart from '@/components/Chart/PieChart'
  import BarChart from '@/components/Chart/BarChart'
  import LineChart from '@/components/Chart/LineChart'
  // Component
  import RapportTableComponent from '@/components/Rapport/RapportTable'
  // Service
  import DashboardService from '@/services/01Cell/DashboardService'

  export default {
    name: 'DashboardChart',
    components: {
      Contract,
      AreaChart,
      BarChart,
      LineChart,
      RapportTableComponent,
    },
    data () {
      return {
        header: {},
        cardHeight: 170,
        chart1: null,
        chart2: null,
        chart3: null,
        chart4: null,
        chart5: null,
        chart6: null,
        graphBar1: null,
        graphBar2: null,
        graphBar3: null,
        graphLine1: null,
        graphLine2: null,
        dialog: {
          state: false,
          title: null,
          type: null,
        },
        tableClientDetails: [],
        tableHeadersDetails: [
          { text: '', value: 'edit', sortable: false, class: 'text-h4 primary--text', width: '120px' },
          { text: 'ID', value: 'id', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Name'), value: 'clientPrenomNom', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.no'), value: 'noContrat', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Category'), value: 'categorie', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('tools.billing.Period'), value: 'periode', sortable: true, class: 'text-h4 primary--text' },
          // { text: this.$i18n.t('contract.bill.Total_before_tax'), value: 'montant_at', sortable: true, class: 'text-h4 primary--text' },
          // { text: this.$i18n.t('contract.bill.Credit_applied'), value: 'credit_applique', sortable: true, class: 'text-h4 primary--text' },
          // { text: 'TPS', value: 'tps', sortable: true, class: 'text-h4 primary--text' },
          // { text: 'TVQ', value: 'tvq', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('dashboard.Late_fee'), value: 'frais_retard', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Total', value: 'montant_total', sortable: true, class: 'text-h4 primary--text' },
        ],
        tableMauvaisPayeur: [],
        tableHeaderMauvaisPayeur: [
          { text: this.$i18n.t('Contract'), value: 'no', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Name'), value: 'client', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Quantity'), value: 'qt', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Total', value: 'total', sortable: true, class: 'text-h4 primary--text' },
        ],
        searchMauvaisPayeur: null,
        tableExEmploye: [],
        tableHeaderExEmploye: [
          { text: 'ID', value: 'id', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Name'), value: 'client', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('dashboard.Contract_number'), value: 'contract_count', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Since'), value: 'date_ex_employe', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('dashboard.Days_since'), value: 'days_since', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('dashboard.Days_left'), value: 'days_left', sortable: true, class: 'text-h4 primary--text' },
        ],
        searchExEmploye: null,
        tableC2HorsLimite: [],
        tableHeaderC2HorsLimite: [
          { text: 'ID', value: 'ClientId', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Name'), value: 'concat', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Quantity'), value: 'qt', sortable: true, class: 'text-h4 primary--text' },
        ],
        searchC2HorsLimite: null,
        searchSpecialEmploye: null,
        tableSpecialEmploye: [],
        tableHeaderSpecialEmploye: [
          { text: 'ID', value: 'id', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Name'), value: 'clientPrenomNom', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('dashboard.Contract_number'), value: 'contractCount', sortable: true, class: 'text-h4 primary--text' },
        ],
        tableFraisAjustement: [],
        tableHeaderFraisAjustement: [
          { text: 'ID', value: 'id', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Name'), value: 'clientPrenomNom', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.bill.Total_before_tax'), value: 'montant_at', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Total', value: 'montant_total', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.Adjusted_invoice'), value: 'frais_ajustement', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Note', value: 'note', sortable: true, class: 'text-h4 primary--text' },
        ],
        searchFraisAjustement: null,
      }
    },
    async mounted () {
      // Start Spinner
      this.$loading.show()
      try {
        this.header = await DashboardService.header()
        // console.log(this.header)

        DashboardService.graph().then(response => {
          this.chart1 = response.graph1.graph
          this.chart2 = response.graph2.graph
          this.chart3 = response.graph3.graph
          this.chart4 = response.graph4.graph
          this.chart5 = response.graph5.graph
          this.chart6 = response.graph6.graph
          // const datasetsChart1 = {
          //   title: {
          //     text: null,
          //     display: true,
          //   },
          //   textCenter: {
          //     display: false,
          //     title: null,
          //     text: null,
          //   },
          //   labels: [],
          //   datasets: [{
          //     backgroundColor: [],
          //     data: [],
          //   }],
          // }

          // // Graph1
          // datasetsChart1.title.text = this.$i18n.t(response.graph1.title).toUpperCase()
          // response.graph1.data.forEach(currentItem => {
          //   datasetsChart1.labels.push(`${this.$i18n.t(`dashboard.Type_employee.${currentItem.label}`)} (${currentItem.count})`)
          //   datasetsChart1.datasets[0].data.push(parseInt(currentItem.count, 10))
          //   datasetsChart1.datasets[0].backgroundColor.push(currentItem.color)
          // })

          // this.chart1 = datasetsChart1
        })

        DashboardService.graphBar().then(response => {
          this.graphBar1 = response.graphBar1.graph
          this.graphBar2 = response.graphBar2.graph
          this.graphBar3 = response.graphBar3.graph
        })

        DashboardService.graphLine().then(response => {
          this.graphLine1 = response.graphLine1.graph
          this.graphLine2 = response.graphLine2.graph
        })

        // this.chart1.datasets`
        // Stop Spinner
        this.$loading.hide()
      } catch (error) {
        console.log(error)
        // Stop Spinner
        this.$loading.hide()
      }
    },
    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
      async openDialogFactures (type, title) {
        // Start Spinner
        this.$loading.show()
        try {
          this.tableClientDetails = await DashboardService.detailsFactures(type)

          this.dialog.title = title
          this.dialog.type = 'facture'
          this.dialog.state = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async openDialogContrats (type, title) {
        // Start Spinner
        this.$loading.show()
        try {
          this.tableClientDetails = await DashboardService.detailsContrats(type)
          this.dialog.title = title
          this.dialog.type = 'contrat'
          this.dialog.state = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async openDialogMauvaisPayeur (title) {
        this.searchMauvaisPayeur = null
        // Start Spinner
        this.$loading.show()
        try {
          this.tableMauvaisPayeur = await DashboardService.mauvaisPayeur()
          this.dialog.title = title
          this.dialog.type = 'mauvaisPayeur'
          this.dialog.state = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async openDialogExEmployeC2Special (title) {
        this.searchExEmploye = null
        this.searchC2HorsLimite = null
        this.searchSpecialEmploye = null
        // Start Spinner
        this.$loading.show()
        try {
          const response = await DashboardService.exEmployeC2()
          this.tableExEmploye = response.exEmploye
          this.tablec2HorsLimite = response.c2HorsLimite
          this.tableSpecialEmploye = response.specialEmploye
          this.dialog.title = title
          this.dialog.type = 'exEmployeC2'
          this.dialog.state = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async openDialogFraisAjustement (title) {
        this.searchFraisAjustement = null
        // Start Spinner
        this.$loading.show()
        try {
          const response = await DashboardService.fraisAjustement()
          this.tableFraisAjustement = response.map(item => {
            // On ne garde que la dernière note
            const note = item.note.slice(-1)
            item.note = note[0].note
            return item
          })
          this.dialog.title = title
          this.dialog.type = 'fraisAjustement'
          this.dialog.state = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>

<style scoped>
  .pointer {
    cursor: pointer;
  }
</style>
